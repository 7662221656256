import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { PageLocaleRedirectQuery } from '../../gatsby-graphql';
import { getBrowserLocale } from '../utils/i18n';
import Seo from '../components/Seo';

interface IPageLocaleRedirectProps {
  data: PageLocaleRedirectQuery;
  pageContext: {
    redirectPath: string;
    seo: {
      title: string;
      description: string;
      image: string;
    };
  };
}

const PageLocaleRedirect: React.FC<IPageLocaleRedirectProps> = ({
  data: { site },
  pageContext: { redirectPath, seo },
}) => {
  useEffect(() => {
    const locale = getBrowserLocale(
      site?.siteMetadata?.languages as string[],
      site?.siteMetadata?.defaultLang as string
    );
    navigate(`/${locale}${redirectPath}`);
  }, []);
  return (
    <Seo
      description={seo.description}
      title={seo.title}
      image={seo.image}
      lang={String(site?.siteMetadata?.defaultLang)}
    />
  );
};

export default PageLocaleRedirect;

export const pageQuery = graphql`
  query PageLocaleRedirect {
    site {
      siteMetadata {
        defaultLang
        languages
      }
    }
  }
`;
